<template>
    <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div class="group relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 hover:text-ifgreen focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-ifgreen">
                <div class="flex-shrink-0 text-ifgray group-hover:text-ifgreen">
                    <DocumentIcon class="h-10 w-10" />
                </div>
                <div class="flex-1 min-w-0">
                <router-link
                    to="/support/documents#document"
                    class="focus:outline-none"
                >
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-900">
                      {{ $t('settings.documents') }}
                    </p>
                </router-link>
                </div>
            </div>
            <div class="group relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-ifgreen">
                <div class="group-hover:text-ifgreen flex-shrink-0 text-ifgray">
                    <VideoCameraIcon class="h-10 w-10" />
                </div>
                <div class="flex-1 min-w-0">
                <router-link
                    to="/support/documents#youtube"
                    class="focus:outline-none"
                >
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-900">
                      {{ $t('settings.videos') }}
                    </p>
                </router-link>
                </div>
            </div>
            <a :href="`tel:${organisation.supportPhoneNumber}`" class="inline-flex w-full items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
              <PhoneIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ $t('settings.callSupport') }}
            </a>
        </div>
    </div>
</template>

<script>
  import { DocumentIcon, VideoCameraIcon, PhoneIcon } from "@heroicons/vue/24/outline";
  import { api } from "@/assets/js/api-client.js";

  export default {
    components: {
        DocumentIcon,
        VideoCameraIcon,
        PhoneIcon
    },
    data(){
      return {
        organisation: {}
      }
    },
    mounted() {
      api.get(`/organisations/${process.env.VUE_APP_ORG_ID}`).then(result => {
        this.organisation = result.data
      }).catch(err => {
        console.log(err)
        this.$notify({
          title: this.$t('home.inspection.errorTitle'),
          text: err.response.data.detail,
          type: "error",
          duration: 10000
        })
      })
    }
  }
</script>
